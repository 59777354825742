/**
 * Formats a given amount in the de-DE currency format
 *
 * @param {number} amount The given amount.
 * @returns {string} The formatted amount in the desired currency.
 */
const formatCurrency = (
  amount: number,
  options?: Record<string, number | string>
) => {
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    // check if whole number
    minimumFractionDigits: amount % 1 == 0 ? 0 : 2,
    ...options,
  }).format(amount)
}

const formatNumber = (amount: number) => {
  return Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(amount)
}

function isJSON(str: string) {
  try {
      return (JSON.parse(str) && !!str);
  } catch (e) {
      return false;
  }
}

const formatStringOnlyDigits = (value: string) => value.replace(/[^0-9]/g, '')

export { formatCurrency, formatNumber, formatStringOnlyDigits, isJSON }
