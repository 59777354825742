'use client'

import { useEffect } from 'react'
import { isNotFoundError } from 'next/dist/client/components/not-found'
import { datadogRum } from '@datadog/browser-rum'
import { redirect } from 'next/navigation'
import { isJSON } from '../../utils/formatting'

const Error = ({ error }) => {
  let statusCode = isNotFoundError(error) ? 404 : 500    
  let traceId = ""

  if (isJSON(error.message)) {
    const foundTraceId = JSON.parse(error.message).traceid
    if (foundTraceId !== undefined) {
      traceId = foundTraceId
    }

    if (JSON.parse(error.message).errorMessage.includes("Not found")) {
      statusCode = 404
    }
  }

  useEffect(() => {
    if (statusCode === 500) {
      datadogRum.addError(error)
    }
  }, [error, statusCode])
  redirect(`/error/${statusCode}/${traceId}`)
}


export default Error

